<script lang="ts">
	import { createDialog, melt } from '@melt-ui/svelte'
	import type { Writable } from 'svelte/store'
	import { fade, scale } from 'svelte/transition'
	import { cubicInOut } from 'svelte/easing'
	import { beforeNavigate } from '$app/navigation'

	export let open: Writable<boolean>
	export let title: string

	const {
		elements: { portalled, overlay, content, title: titleElement, close },
		states: { open: isOpen },
	} = createDialog({
		open,
	})

	beforeNavigate(() => {
		open.set(false)
	})
</script>

<div use:melt={$portalled}>
	{#if $isOpen}
		<div
			use:melt={$overlay}
			transition:fade={{ duration: 100, easing: cubicInOut }}
			class="overlay"
		/>

		<div
			use:melt={$content}
			transition:scale={{
				duration: 250,
				start: 0.9,
				easing: cubicInOut,
			}}
			class="fixed left-1/2 top-1/2 z-5 flex max-h-[calc(100svh-30px)] w-[calc(100vw-30px)] max-w-2xl -translate-x-1/2 -translate-y-1/2 flex-col rounded-lg bg-base-light shadow"
		>
			<div
				class="flex items-center justify-between rounded-t border-b border-base-lightest p-3 sm:p-4"
			>
				<h3 use:melt={$titleElement} class="text-xl font-bold text-base-content">{title}</h3>

				<button
					type="button"
					class="ml-auto flex size-10 items-center justify-center rounded-lg p-1 text-base-content-light hover:bg-base-lightest focus-visible:bg-base-lightest focus-visible:ring"
					use:melt={$close}
					aria-label="Close dialog"
				>
					<svg
						xmlns="http://www.w3.org/2000/svg"
						width="24"
						height="24"
						viewBox="0 0 24 24"
						fill="none"
						stroke="currentColor"
						stroke-width="2"
						stroke-linecap="round"
						stroke-linejoin="round"
					>
						<path d="M18 6 6 18" />
						<path d="m6 6 12 12" />
					</svg>
				</button>
			</div>

			<div class="overflow-auto p-3 sm:p-4">
				<slot />
			</div>
		</div>
	{/if}
</div>
