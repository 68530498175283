/* eslint-disable */
/** 
* This file contains language specific message functions for tree-shaking. 
* 
*! WARNING: Only import messages from this file if you want to manually
*! optimize your bundle. Else, import from the `messages.js` file. 
* 
* Your bundler will (in the future) automatically replace the index function 
* with a language specific message function in the build step. 
*/
/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const chain_page_get_listed_dialog_general_community_memeber_instructions = () => `If you want to see your favorite blockchain listed on Chainspect, the best approach is to reach the blockchain via social media. Let them know you’re interested in seeing their performance metrics on Chainspect, and ask them to consider getting listed!`
